<template>
  <div id="app">
    <router-view></router-view>

    <right_fun v-if="$route.name != 'h5'"></right_fun>
  </div>
</template>

<script>
import right_fun from './components/right_fun.vue'
export default {
  name: 'App',
  components: {
    right_fun
  },
  created() {
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        // 当前标签页被切换到后台
        console.log('标签页被切换到后台');
      } else {
        // 当前标签页被切换到前台
        console.log('标签页被切换到前台');
      }
    });
    this.indexData();
  },
  mounted() {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
    console.log(this.$route)
  },
  methods: {
    navH5() {
      console.log(window.screen.width * window.devicePixelRatio)
    },
    checkWidth() {
      if (window.innerWidth < 670) {
        this.$router.push({
          name: "h5"
        })
        // window.open(this.$router.resolve({
        //   name: 'h5'
        // }).href, '_blank');
      }
      // if (window.innerWidth < 1200) {
      //   document.body.style.overflowX = 'scroll';
      // } else {
      //   document.body.style.overflowX = 'hidden';
      // }
    },
    indexData() {
      this.$axios({
        url: "/api/index/index_data",
      }).then(res => {
        this.$store.commit('setBanner', res.data.banner);
        this.$store.commit('setCateList', res.data.cate_list);
        this.$store.commit('setCateProducts', res.data.cate_products);
        this.$store.commit('setSys', res.data.sys);
        this.$store.commit('setUserinfo', res.data.user);


        // this.cate_list = res.data.cate_list;
        // this.cate_products = res.data.cate_products;
        // this.swiperList = res.data.banner
      })
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth);
  }
}
</script>

<style lang="scss">
@import url("assets/css/index.css");
.flex {
  display: flex;
  align-items: center;
}
</style>
