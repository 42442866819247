// 第一步引入插件(本质是一个构造函数)
import VueRouter from 'vue-router'
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch(error => error)
}
// 第二步创建router实例对象并暴露
const router = new VueRouter({
  mode: "history",
  routes: [
    // {
    //   path: '/',
    //   alias: "/home"
    // },
    {
      alias: "/",
      path: '/home',
      name: "home",
      component: () => import('@/views/home'),
    },
    {
      path: '/labelPage',
      name: "labelPage",
      component: () => import('@/views/labelPage'),
    },
    {
      path: "/goodsDetails",
      name: "goodsDetails",
      component: () => import('@/views/goodsDetails')
    },
    {
      path: "/shopping",
      name: "shopping",
      component: () => import('@/views/shopping')
    },
    {
      path: "/order_step1",
      name: "order_step1",
      component: () => import('@/views/order_step1')
    },
    {
      path: "/userCenter",
      name: "home/userCenter",
      component: () => import('@/views/userCenter/index.vue'),
      redirect: { name: 'personal' },
      children: [
        {
          path: "/userCenter",
          name: "personal",
          component: () => import('@/views/userCenter/personal'),
          meta: {
            title: "personal"
          }
        },
        {
          path: "/userCenter/agent",
          name: "agent",
          component: () => import('@/views/userCenter/agent'),
          meta: {
            title: "agent"
          }
        },
        {
          path: "/userCenter/orderList",
          name: "orderList",
          component: () => import('@/views/userCenter/orderList'),
          meta: {
            title: "orderList"
          }
        },
        {
          path: "/userCenter/applicationList",
          name: "applicationList",
          component: () => import('@/views/userCenter/applicationList'),
          meta: {
            title: "applicationList"
          }
        },
        {
          path: "/userCenter/collectionList",
          name: "collectionList",
          component: () => import('@/views/userCenter/collectionList'),
          meta: {
            title: "collectionList"
          }
        },
        {
          path: "/userCenter/assetList",
          name: "assetList",
          component: () => import('@/views/userCenter/assetList'),
          meta: {
            title: "assetList"
          }
        },

        {
          path: "/userCenter/profile",
          name: "profile",
          component: () => import('@/views/userCenter/profile'),
          meta: {
            title: "profile"
          }
        },
        {
          path: "/userCenter/addressList",
          name: "addressList",
          component: () => import('@/views/userCenter/addressList'),
          meta: {
            title: "addressList"
          }
        },
        {
          path: "/userCenter/account_security",
          name: "account_security",
          component: () => import('@/views/userCenter/account_security'),
          meta: {
            title: "account_security"
          }
        },
        {
          path: "/userCenter/messageList",
          name: "messageList",
          component: () => import('@/views/userCenter/messageList'),
          meta: {
            title: "messageList"
          }
        },

      ]
    },
    {
      path: "/login",
      name: "login",
      component: () => import('@/views/login/index.vue')
    },
    {
      path: "/pay_status",
      name: "pay_status",
      component: () => import('@/views/pay_status.vue')
    },
    {
      path: "/orderInfo",
      name: "orderInfo",
      component: () => import('@/views/orderInfo'),
    },
    {
      path: "/templateDownload",
      name: "templateDownload",
      component: () => import('@/views/templateDownload'),
    },
    {
      path: "/404",
      name: "404",
      component: () => import('@/views/404'),
    },
    {
      path: "/ceshi",
      name: "ceshi",
      component: () => import('@/views/ceshi/index.vue')
    },
    {
      path: "/h5",
      name: "h5",
      component: () => import('@/views/h5.vue')
    }
  ]
})


router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth' // 平滑滚动
  });
  console.log(to)
  if (to.name == 'personal' && !localStorage.getItem('token')) {
    next(
      {
        name: 'login',
      }
    )
  } else {
    next();
  }
})


export default router