//全局过滤器

const filter = (Vue) => {
    Vue.filter('friendlyDate', (time) => {
        if (time < 90000000000) { time *= 1000; }
        var timer = new Date().getTime() - time;
        timer = parseInt(timer / 1000);
        if (timer < 180) {
            return '刚刚';
        } else if (timer >= 180 && timer < 3600) {
            return parseInt(timer / 60) + '分钟前';
        } else if ((timer >= 3600) && (timer < 86400)) {
            return parseInt(timer / 3600) + '小时前';
        } else {
            return parseInt(timer / 86400) + '天前';
        }
    });
    Vue.filter('toDate', (time) => {
        var date = new Date(time * 1000);
        var YY = date.getFullYear() + '-';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        return YY + MM + DD;
    });

    Vue.filter('toDateTime', (time) => {
        var date = new Date(time * 1000);
        var YY = date.getFullYear() + '-';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        return YY + MM + DD + ' ' + hh + mm;
    });
    Vue.filter('toMD', (time) => {
        var date = new Date(time * 1000);
        var YY = date.getFullYear();
        var MM = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1);
        var DD = (date.getDate() < 10 ? (date.getDate()) : date.getDate());
        return YY + "-" + MM + '-' + DD;
    });

    Vue.filter('theParseFloat', (n) => {
        return parseFloat(parseFloat(n).toPrecision(12));
    });

    Vue.filter('toFixed2', (n) => {
        return parseFloat(n).toFixed(2);
    });

    Vue.filter('theCeil', (n) => {
        return (Math.floor(n * 100) / 100).toFixed(2);
    })

}

export default filter
