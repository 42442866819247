import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui';

let isDispose = true //全局处理code非200请求

axios.defaults.baseURL = 'https://api.ekuaifa.com/';
axios.defaults.timeout = 5000;
// axios.defaults.withCredentials = true;
// 处理axios请求
axios.interceptors.request.use(
    function (config) {
        config.headers['Content-Type'] = 'application/json';
        const token = localStorage.getItem('token') || null
        // if (config.data) {
        //     Object.assign(config.data)
        // }
        // 配置token和userid
        if (token) {
            config.headers['Token'] = token
        }
        // 是否需要**自定义**处理code非200
        if (config.needPrivate) {
            isDispose = false
            // delete config.needPrivate
        } else {
            isDispose = true
        }
        return config;
    }, function (error) {
        Notification.error("网络异常")
        return Promise.reject(error);
    });

// 处理axios响应
axios.interceptors.response.use(res => {
    if (res.data.code == 1) {
        return res.data;
    } else if (res.data.code == 0) {//
        Message.error({
            message: res.data.msg,
            offset: 100,
        })
        return Promise.reject(res.data)
        // return Promise.reject(new Error('宝儿，要先登录哦'))
        // router.push("/login")
    } else if (res.data.code == 401) {

        return;

    } else if (!res.config.needPrivate && res.data.code != 200) { //全局处理非200
        // Notification({
        //     title:"错误",
        //     message:res.data.msg || '系统错误',
        //     type:"error",
        //     duration:2000,
        // })
        // return Promise.reject(new Error('接口报错啦'))
        let url = '/mqtt/actionIncentive/actionLogPc' //过滤日志接口

    } else if (res.config.needPrivate) {//不需要全局处理
        console.log('res.da', res)
        return res
    }
}, error => {
    console.log("err=>", error)
    if (error.response.status == '401') {
        console.log('123')
        router.push({
            name: "login"
        })
    }

    // let { message } = error;
    // if (message == "Network Error") {
    //     message = "后端接口连接异常";
    // } else if (message.includes("timeout")) {
    //     message = "系统接口请求超时";
    // } else if (message.includes("Request failed with status code")) {
    //     message = "系统接口" + message.substr(message.length - 3) + "异常";
    // }

    // Notification.error(message)
    Promise.reject(error)
});

export default axios
