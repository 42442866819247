import Vue from 'vue'
import App from './App.vue'
// 引入router 插件
import VueRouter from 'vue-router'
// 使用插件
Vue.use(VueRouter)
import "@/assets/iconfont/iconfont.css"
import store from './store'
import router from "./router";
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import axios from './axios'
Vue.prototype.$axios = axios //axios请求
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
//过滤器
import filter from '@/libs/filter';
Vue.use(VueAwesomeSwiper)
filter(Vue);
import VueImageHandler from 'vue-image-handler'
Vue.use(VueImageHandler)

Vue.config.productionTip = false;
new Vue({
  // 指定容器
  el: '#app',
  // 解析App模板
  render: h => h(App),
  // 使用路由
  router,
  store,
})