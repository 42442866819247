import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userinfo: {},  //个人信息
        cartNum: 0,
        infoAvars: "",
        keywords: "",  //搜索条件
        swiperList: {},
        cate_list: {},
        cate_products: {},
        sys: {},
    },
    getters: {
    },
    mutations: {
        setUserinfo(state, data) {
            state.userinfo = data;
            localStorage.setItem('token', data.token)
            localStorage.setItem('userinfo', JSON.stringify(data))
        },
        setCartNum(state, data) {
            state.cartNum = data;
        },
        setKeyWord(state, data) {
            state.keywords = data;
        },
        setBanner(state, data) {
            state.swiperList = data;
        },
        setCateList(state, data) {
            state.cate_list = data;
        },
        setCateProducts(state, data) {
            state.cate_products = data;
        },
        setSys(state, data) {
            state.sys = data;
        },

    },
    actions: {
    },
    modules: {

    }
})
